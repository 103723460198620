<template>
  <div class="row">
    <div class="col-12 col-lg-9">
      <card class="parent-card">
        <template v-slot:header>
          <h5 class="h3 mb-0">
            {{ getTrans("messages.select_token_package") }}
          </h5>
        </template>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
            v-for="tokenP in packages"
            :key="tokenP.name"
          >
            <card class="text-center h-100" type="radio">
              <p class="card-text">{{ tokenP.name }}</p>
              <h2>{{ tokenP.value }}</h2>
              <h5>{{ getTrans("messages.tokens") }}</h5>
              <hr />
              <div class="row justify-content-center">
                <h2 class="col">{{ tokenP.price }}</h2>
                <base-button
                  class="align-self-end"
                  size="sm"
                  type="primary"
                  @click="selectPackage(tokenP)"
                  >{{ getTrans("messages.select") }}</base-button
                >
              </div>
            </card>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
            <card class="text-center h-100" type="radio" id="loadyourAmount">
              <p class="card-text">{{ getTrans("messages.custom_amount") }}</p>
              <base-input
                v-model="customTokens"
                :tag="'input'"
                @blur="customTokensBlur"
              ></base-input>

              <h5>{{ getTrans("messages.tokens") }}</h5>
              <hr />
              <div class="row justify-content-center">
                <h2 class="col">{{ customTokensPrice }}</h2>
                <base-button
                  class="align-self-end"
                  size="sm"
                  type="primary"
                  @click="selectCustomPackage()"
                  >{{ getTrans("messages.select") }}</base-button
                >
              </div>
            </card>
          </div>
        </div>
      </card>
    </div>
    <div id="tokensummary" class="col-12 col-lg-3">
      <token-summary
        :tokenPackage="selected"
        @selectCustomPackage="selectCustomPackage"
      ></token-summary>
    </div>
  </div>
</template>
<script>
// Components
import BaseButton from "@/components/BaseButton.vue";
import Card from "@/components/Cards/Card.vue";
import Summary from "./Summary";
import { ElSelect, ElOption } from "element-plus";

export default {
  name: "Tokens",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    BaseButton,
    Card,
    "token-summary": Summary,
  },
  props: ["value"],
  data() {
    return {
      editPrice: false,
      customTokens: 30000,
      packages: [
        {
          name: "XSmall",
          value: 100,
          price: 10,
        },
        {
          name: "Small",
          value: 250,
          price: 25,
        },
        {
          name: "Medium",
          value: 1000,
          price: 100,
        },
        {
          name: "Large",
          value: 2500,
          price: 250,
        },
        {
          name: "XL",
          value: 5000,
          price: 500,
        },
        {
          name: "XXL",
          value: 10000,
          price: 1000,
        },
        {
          name: "XXXL",
          value: 25000,
          price: 2500,
        },
      ],
      selected: {
        name: "Small",
        value: 250,
        price: 25,
      },
    };
  },
  computed: {
    customTokensPrice() {
      if (this.customTokens && this.customTokens > 100) {
        return this.customTokens / 10;
      }
      return 10;
    },
  },
  watch: {
    customTokens() {
      this.selectCustomPackage();
    },
  },
  methods: {
    customTokensBlur() {
      if (this.customTokens < 100) {
        this.customTokens = 100;
      }
    },
    scrolltoElement(element) {
      document.getElementById(element).scrollIntoView({ behavior: "smooth" });
    },
    selectPackage(value) {
      this.selected = value;
      this.scrolltoElement("tokensummary");
    },
    selectCustomPackage(tPackage) {
      if (tPackage) {
        if (tPackage.value < 10) {
          this.customTokens = 10;
          this.selectCustomPackage();
          return;
        }
        this.selected = tPackage;
      } else {
        if (isNaN(this.customTokens) || this.customTokens < 100) {
          this.selected = {
            name: "Custom Package",
            value: 100,
            price: 10,
          };
          return;
        }
        this.customTokens = Number.parseFloat(this.customTokens);
        this.selected = {
          name: "Custom Package",
          value: this.customTokens,
          price: this.customTokens / 10,
        };
      }

      this.scrolltoElement("tokensummary");
    },
  },
  mounted() {
    this.selected = this.packages[1];
  },
};
</script>
<style></style>
