<template>
    <div>
        <form
        action="https://www.paxum.com/payment/phrame.php?action=displayProcessPaymentLogin"
        method="post"
        id="paxum-form"
        >
            <input type="hidden" name="business_email" value="mail@divatraffic.com" />
            <input type="hidden" name="button_type_id" value="1" />
            <input type="hidden" name="item_id" value="0" />
            <input type="hidden" name="item_name" :value="name" />
            <input id="paxum-price" type="hidden" name="amount" :value="current_total" />
            <input type="hidden" name="currency" value="USD" />
            <input type="hidden" name="sandbox" value="ON" />
            <input type="hidden" name="cancel_url" v-model="payment_fail_url" />
            <input type="hidden" name="finish_url" v-model="payment_success_url" />
            <input type="hidden" name="variables" v-model="paxum_postback_url" />
        </form>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: 'payments-template',
    props: {
        name: {
            type: String,
            default: 'Custom package'
        },
        current_total: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            payment_success_url: process.env.VUE_APP_URL + "/payments/?status=success_paxum",
            payment_fail_url: process.env.VUE_APP_URL + "/payments/?status=fail",
        }
    },
    computed: {
        ...mapGetters("User", ["getUser"]),
        paxum_postback_url() {
            return (
                "notify_url=" +
                process.env.VUE_APP_API_URL +
                "paxum_postback/&diva_id=" +
                this.getUser.id
            );
        },
    }
}
</script>